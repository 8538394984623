<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="商家信息：">
        <el-input size="small" v-model="searchForm.contact_name" placeholder="请输入用户姓名"></el-input>
      </el-form-item>
      <el-form-item label="商家电话：">
        <el-input size="small" v-model="searchForm.store_phone" placeholder="请输入商家电话"></el-input>
      </el-form-item>
      <el-form-item label="服务商姓名：">
        <el-input size="small" v-model="searchForm.name" placeholder="请输入服务商姓名"></el-input>
      </el-form-item>
      <el-form-item label="服务商电话：">
        <el-input size="small" v-model="searchForm.mobile" placeholder="请输入服务商电话"></el-input>
      </el-form-item>
      <el-form-item label="店铺名称：">
        <el-input size="small" v-model="searchForm.store_name" placeholder="请输入店铺名称"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="contact_name" label="商家信息" width="200" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.contact_name" :avatar="row.store_logo"></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="store_phone" label="商家电话" align="center"></el-table-column>
      <el-table-column prop="name" label="服务商姓名" align="center"></el-table-column>
      <el-table-column prop="mobile" label="服务商电话" align="center"></el-table-column>
      <el-table-column prop="store_name" label="店铺名称" align="center"></el-table-column>
      <el-table-column prop="earnings" label="收益比例" align="center">
        <template v-slot="{ row }">{{ row.earnings }}%</template>
      </el-table-column>
      <el-table-column prop="total_amount" label="总收益" align="center">
        <template v-slot="{ row }">￥{{ row.total_amount }}</template>
      </el-table-column>
      <el-table-column prop="amount" label="当前余额" align="center">
        <template v-slot="{ row }">￥{{ row.amount }}</template>
      </el-table-column>
      <el-table-column prop="withdrawal_amount" label="累计提现金额" align="center">
        <template v-slot="{ row }">￥{{ row.withdrawal_amount }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="seeDetailed(row)" type="text" size="small">查看明细</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="查看明细" :visible.sync="showDetailed" width="780px">
      <el-table :data="balanceList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="create_time" label="生成时间" align="center">
          <template v-slot="{ row }">
            {{ getDateformat(row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="收益支出" align="center">
          <template v-slot="{ row }">
            {{ row.type == 1 ? 'wifi' : row.type == 2 ? '第三方活动' : row.type == 3 ? '扫码点餐' : '提现' }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="预估收益" align="center">
          <template v-slot="{ row }">{{ row.income_type == 1 ? '+' : '-' }}{{ row.amount }}</template>
        </el-table-column>
      </el-table>
      <Paging :total="balance_total" :page="balance_page" :pageNum="balance_rows" @updatePageNum="balanceUpdateData"></Paging>
    </el-dialog>
  </el-main>
</template>

<script>
import userInfo from '@/components/userInfo';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    userInfo,
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        contact_name: '',
        store_phone: '',
        name: '',
        mobile: '',
        store_name: '',
      },
      id: '',
      list: [],
      balanceList: [],
      total_number: 0,
      showDetailed: !1,
      balance_page: 1,
      balance_rows: 10,
      balance_total: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        contact_name: '',
        store_phone: '',
        name: '',
        mobile: '',
        store_name: '',
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    balanceUpdateData(val, status) {
      if (status == 0) {
        this.balance_rows = val;
      } else {
        this.balance_page = val;
      }
      this.getBalanceList();
    },
    getBalanceList() {
      this.$axios
        .post(this.$api.serviceProvider.BalanceLog, {
          page: this.balance_page,
          rows: this.balance_rows,
          identity_type: 2,
          store_id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.showDetailed = !0;
            this.balanceList = res.result.list;
            this.balance_total = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    seeDetailed(row) {
      this.id = row.id;
      this.balance_page = 1;
      this.getBalanceList();
    },
    getList() {
      this.$axios.post(this.$api.set.storeList, this.searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>